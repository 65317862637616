<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showform"
        max-width="600px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Mise a jour du Mot de Passe </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.old_password"
                    label="Mot de Passe Actuel"
                    outlined
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[(v) => !!v || 'Mot de passe obligatoire']"
                    counter
                    @click:append="show = !show"
                    @focus="handleType"
                    @blur="handleType"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.password"
                    label="Nouveau Mot de Passe "
                    hint="Minimum 8 characteres"
                    outlined
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    :rules="[
                      (v) => !!v || 'Mot de passe obligatoire',
                      (v) => v.length >= 8 || 'Longueur minimale 8',
                      () =>
                        editedItem.password != editedItem.old_password ||
                        'Mot de passe actuel!! erreur',
                    ]"
                    counter
                    @click:append="show1 = !show1"
                    @focus="handleType"
                    @blur="handleType"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    autocomplete="off"
                    ref="pswconf"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    hint="Minimum 8 characteres"
                    outlined
                    dense
                    v-model="editedItem.password_confirmation"
                    label="Confirmer le mot de passe"
                    required
                    :rules="[
                      (v) => !!v || 'Confirmation obligatoire',
                      () =>
                        editedItem.password ===
                          editedItem.password_confirmation ||
                        'Confirmation incorrecte',
                    ]"
                    counter
                    @click:append="show1 = !show1"
                    @focus="handleType"
                    @blur="handleType"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>
              {{ message }}
            </v-alert>
            <v-spacer></v-spacer>

            <v-btn
              color="blue darken-1"
              @click="change_psw"
              :loading="loading"
              :disabled="editedItem.old_password == ''"
            >
              Changer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close"> Annuler </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import UPDATE_PSW from "../graphql/Users/UPDATE_PSW.gql";

export default {
  name: "pswform",
  props: {
    me: Object,
    showform: Boolean,
  },
  data: () => ({
    message: "",
    type_alert: "info",
    editedItem: { old_password: "", password: "", password_confirmation: "" },
    pws_change: 0,
    show: false,
    show1: false,
    loading: false,
    valid: true,
    alert: false,
    fieldTypes: {
      // add this for change input type
      password: "text",
    },
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {},
  watch: {},

  created() {},

  methods: {
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          this.loading = false;
          r = data.data;
        })
        .catch(() => {
          this.loading = false;
        });
      return r;
    },
    async change_psw() {
      if (this.$refs.form.validate()) {
        let v = {
          input: {
            old_password: this.editedItem.old_password,
            password: this.editedItem.password,
            password_confirmation: this.editedItem.password_confirmation,
            mpc: 0,
          },
        };
        let r = await this.maj(UPDATE_PSW, v);
        if (r) {
          if (r.updatePassword.status == "PASSWORD_UPDATED") {
            this.snackbar_text = "Mot de passe mis a jour avec succes";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.$emit("pwd_changed");
          }
        } else {
          this.message = "Erreur Mot de passe";
          this.type_alert = "error";
          this.alert = true;
        }
      }
    },
  },
};
</script>
